import React from "react";
import type { IconName } from "@blueprintjs/icons";
import type { IBreadcrumbProps, MaybeElement } from "@blueprintjs/core";
import { Boundary, Breadcrumbs, Icon } from "@blueprintjs/core";
import classNames from "classnames";
import style from "./MultiTabsLayout.module.css";
import SdButton from "../theming/SdButton";
import Tabs from "../theming/Tabs";
import type { TabProps } from "../theming/Tabs/Tabs";
import Loading from "../Loading/Loading";

type Action = {
  onClick: () => void;
  label: string;
  icon?: IconName | MaybeElement;
  display?: boolean;
  isRiskAction?: boolean;
};

export type StatusColor = "success" | "error" | "warning" | "info" | "note";

export type StatusStandard = {
  icon?: React.ComponentProps<typeof Icon>["icon"];
  onClick?: () => void;
  getText: (() => string) | string;
  getColor: StatusColor | (() => StatusColor);
};

type MultiTabsLayoutProps = {
  tabs: TabProps[];
  title: string | React.ReactNode;
  headingNode?: React.ReactNode;
  status?: React.ReactNode | StatusStandard;
  onDelete?: () => void;
  onEdit?: () => void;
  actions?: Action[];
  children?: React.ReactNode;
  breadcrumbs?: IBreadcrumbProps[];
  isLoading?: boolean;
};

const MultiTabsLayout: React.FC<MultiTabsLayoutProps> = ({
  tabs,
  title,
  status,
  headingNode,
  onEdit,
  onDelete,
  children,
  actions,
  breadcrumbs,
  isLoading,
}) => {
  const renderStatus = () => {
    if (!status) return null;

    if (React.isValidElement(status)) {
      return status; // Custom status element provided by the user
    }

    if (
      typeof status === "object" &&
      "getText" in status &&
      "getColor" in status
    ) {
      const { getText, getColor, icon, onClick } = status as StatusStandard;
      const text = typeof getText === "string" ? getText : getText();
      const color = typeof getColor === "string" ? getColor : getColor();
      return (
        <div
          className={classNames(
            style.status,
            {
              [style.status__error]: color === "error",
              [style.status__info]: color === "info",
              [style.status__note]: color === "note",
              [style.status__warning]: color === "warning",
              [style.status__success]: color === "success",
            },
            { [style.status__hoverable]: typeof onClick !== "undefined" } // Add a class only if onClick exists
          )}
          onClick={onClick}
        >
          {icon && <Icon icon={icon} />}
          <span>{text}</span>
        </div>
      );
    }

    return null;
  };
  const filteredTabs = tabs.filter((t) => !t.hide);

  return (
    <div>
      <div className={style.topBar}>
        <div>
          {breadcrumbs && breadcrumbs.length > 0 && (
            <Breadcrumbs
              collapseFrom={Boundary.START}
              items={breadcrumbs}
              className={style.breadcrumbs}
            />
          )}
          <div className="flex items-center">
            <div className={style.title}>{title}</div>
            {status && !isLoading && renderStatus()}
          </div>
          {headingNode}
        </div>
        <div className={style.actions}>
          {onEdit && (
            <SdButton
              className={onDelete && "mr-2"}
              onClick={onEdit}
              icon="edit"
            >
              Edit
            </SdButton>
          )}
          {onDelete && (
            <SdButton onClick={onDelete} icon="trash">
              Delete
            </SdButton>
          )}
          {actions &&
            actions.map((action, idx) =>
              action.display ? (
                <SdButton
                  key={action.label}
                  onClick={action.onClick}
                  className={idx > 0 && idx < actions.length ? "mr-2" : ""}
                  icon={action.icon}
                  danger={action.isRiskAction}
                >
                  {action.label}
                </SdButton>
              ) : null
            )}
        </div>
      </div>
      {isLoading ? <Loading /> : children}
      {tabs.length > 0 && (
        <Tabs
          tabs={tabs}
          defaultSlug={filteredTabs.length > 0 ? filteredTabs[0].slug : ""}
        />
      )}
    </div>
  );
};
export default MultiTabsLayout;
