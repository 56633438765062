import React, { useEffect, useState } from "react";
import { InputGroup } from "@blueprintjs/core";
import classNames from "classnames";
import FilteredView from "../../../../../../components/FilteredView";
import { ENV_VAR_EDITOR_COLUMNS } from "./columns";
import styles from "./EnvVarEditor.module.css";
import { useEnvVarEditor } from "./Context";
import SdButton from "../../../../../../components/theming/SdButton";

const EnvVarEditor = () => {
  const {
    computedEnvVars,
    addEnvVar,
    arePendingChanges,
    applyChangesToSandbox,
    editing,
    setEditing,
  } = useEnvVarEditor();

  const [newEnvVar, setNewEnvVar] = useState<{
    name?: string;
    value?: string;
    isEditing: boolean;
    exists?: boolean;
  }>({ isEditing: false });

  useEffect(() => {
    if (editing) {
      setNewEnvVar({
        name: editing.envVarName,
        value: editing.value,
        isEditing: true,
        exists: true,
      });
    } else {
      setNewEnvVar({ isEditing: false });
    }
  }, [editing]);

  const handleAddNewEnvVar = () => {
    addEnvVar({
      name: newEnvVar.name ?? "unknown",
      value: newEnvVar.value ?? "unknown",
      type: "value",
      container: "",
    });
    setNewEnvVar({ isEditing: false });
  };

  return (
    <>
      <FilteredView
        title={undefined}
        columns={ENV_VAR_EDITOR_COLUMNS}
        rawData={computedEnvVars}
        columnsHeaders={[
          "Key",
          "Value",
          { label: "Source", className: styles.headerSource },
          "",
        ]}
        totalWeight={8}
        rowClassName={styles.row}
      />
      {newEnvVar.isEditing ? (
        <div className={styles.section_actions}>
          <InputGroup
            className="pr-2 w-56"
            placeholder="Environment Variable Name"
            value={newEnvVar.name}
            onChange={(e) =>
              setNewEnvVar((prev) => ({ ...prev, name: e.target.value }))
            }
            disabled={newEnvVar.exists}
          />
          <InputGroup
            className="pr-4 w-56"
            placeholder="Environment Variable Value"
            value={newEnvVar.value}
            onChange={(e) =>
              setNewEnvVar((prev) => ({ ...prev, value: e.target.value }))
            }
          />
          <div className={classNames(styles.section_actions, "mt-0")}>
            <SdButton
              disabled={!newEnvVar.name?.length || !newEnvVar.value?.length}
              onClick={handleAddNewEnvVar}
            >
              {newEnvVar.exists ? "Update" : "Add"} Environment Variable
            </SdButton>
            <SdButton
              danger
              onClick={() => {
                setEditing(undefined);
                setNewEnvVar({ isEditing: false });
              }}
            >
              Cancel
            </SdButton>
          </div>
        </div>
      ) : (
        <div className={styles.section_actions}>
          <SdButton
            className="mb-2"
            onClick={() => setNewEnvVar({ isEditing: true })}
            disabled={newEnvVar.isEditing}
          >
            Add variable
          </SdButton>
          <SdButton
            className="mb-2"
            disabled={!arePendingChanges}
            onClick={applyChangesToSandbox}
          >
            Apply
          </SdButton>
        </div>
      )}
    </>
  );
};

export default EnvVarEditor;
