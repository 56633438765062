import React from "react";
import { Tag } from "@blueprintjs/core";
import styles from "./JobDetailStatus.module.css";
// @ts-ignore
// eslint-disable-next-line import/extensions
import type { AttemptPhaseEnum } from "../../../../../@types/sd/job.d.ts";
import type {
  StatusColor,
  StatusStandard,
} from "../../../../../components/MultiTabsLayout/MultiTabsLayout";

type JobState = AttemptPhaseEnum;

interface JobDetailStatusProps {
  status: JobState;
}

// Not too great an approach, but it works for now.
const jobStatusColorMap: Record<JobState, StatusColor> = {
  queued: "info",
  running: "warning",
  succeeded: "success",
  canceled: "note",
  failed: "error",
};

const JobDetailStatus: React.FC<JobDetailStatusProps> = ({ status }) => (
  <Tag round className={`${styles.icon} ${jobStatusColorMap[status]}`}>
    {status}
  </Tag>
);

export const getJobStatus = (status: JobState): StatusStandard => ({
  getText: status,
  getColor: jobStatusColorMap[status],
});

export default JobDetailStatus;
