import { mergeSandboxSpecForImageUpdate } from "./mergeSandboxSpecForEnvVarsUpdate";
import type { SandboxV2 } from "../../../../../@types/sd/sandboxv2";
import { getSandboxSpecAsYAML } from "../../../../../components/theming/ViewSpecDialog/hook";

export type UseApplyImagePatchProps = {
  sandbox: SandboxV2;
  baselineName: string;
  forkNamespace: string;
  image: string;
};

export type UseApplyImagePatchReturn = {
  updater: (updatedSpec: string) => void;
};

export const useApplyImage = ({
  sandbox,
  baselineName,
  forkNamespace,
  image,
}: UseApplyImagePatchProps) => {
  const apply = (updater: (updatedSpec: string) => void) => {
    const updatedSandbox = mergeSandboxSpecForImageUpdate({
      sandbox,
      image: image,
      fork: {
        name: baselineName ?? "",
        namespace: forkNamespace,
      },
    });

    updater(getSandboxSpecAsYAML(updatedSandbox));
  };

  return {
    apply,
  };
};
