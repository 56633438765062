import React, { useCallback, useState } from "react";
import { Alert, Drawer, Intent } from "@blueprintjs/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteRouteGroupApi,
  useRouteGroupByNameApi,
} from "../../api/RouteGroupsApi";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import CreateRouteGroup from "./CreateRouteGroup";
import MultiTabsLayout, {
  type StatusStandard,
} from "../../components/MultiTabsLayout/MultiTabsLayout";
import {
  RouteGroupDetailTabOverview,
  RouteGroupDetailTabSpecification,
} from "../../Constants";
import RouteGroupOverview from "./Tabs/RouteGroupOverview/RouteGroupOverview";
import RouteGroupSpec from "./Tabs/RouteGroupSpec/RouteGroupSpec";

const getRouteGroupStatus = (ready: boolean): StatusStandard => ({
  getText: ready ? "Ready" : "Not Ready",
  getColor: ready ? "success" : "error",
});

const RouteGroupDetails: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { routeGroupName } = useParams<{ routeGroupName: string }>();
  const routeGroupApi = useRouteGroupByNameApi(routeGroupName!);
  const routeGroupDeleteApi = useDeleteRouteGroupApi();

  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);

  const handleEdit = useCallback(() => {
    setEditOpen(true);
  }, []);
  const handleEditClose = useCallback(() => {
    setEditOpen(false);
  }, []);

  const handleDeleteClick = useCallback(() => {
    setDeleteOpen(true);
  }, []);
  const handleDeleteCancel = useCallback(() => {
    setDeleteOpen(false);
  }, []);
  const handleDeleteConfirm = useCallback(() => {
    routeGroupDeleteApi.mutate({
      url: `/api/v2/orgs/:orgName/routegroups/${routeGroupName}`,
    });
    navigate(`/routegroups`);
  }, [routeGroupName]);

  if (routeGroupApi.error) {
    return <Error text={routeGroupApi.error.message} />;
  }
  if (routeGroupApi.isLoading || !routeGroupApi.data) {
    return <Loading />;
  }

  const tabs = [
    {
      label: "Overview",
      slug: "overview",
      eventName: RouteGroupDetailTabOverview,
      content: <RouteGroupOverview routeGroup={routeGroupApi.data} />,
    },
    {
      label: "Specification",
      slug: "specification",
      eventName: RouteGroupDetailTabSpecification,
      content: <RouteGroupSpec routeGroup={routeGroupApi.data} />,
    },
  ];

  return (
    <div>
      <Drawer onClose={handleEditClose} isOpen={isEditOpen}>
        <CreateRouteGroup
          setDrawerOpen={setEditOpen}
          routeGroup={routeGroupApi.data}
        />
      </Drawer>

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete Route Group"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={isDeleteOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      >
        <div>
          <p>
            Are you sure you want to delete the Route Group:{" "}
            <b>{routeGroupName}</b>
          </p>
        </div>
      </Alert>

      <MultiTabsLayout
        tabs={tabs}
        title={routeGroupApi.data.name}
        status={getRouteGroupStatus(routeGroupApi.data.status.ready)}
        onEdit={handleEdit}
        breadcrumbs={[
          {
            // icon: <Icon icon="layout" color={SdTheme.Link.lightBackground} />,
            text: "Route Groups",
            onClick: () => navigate(`/routegroups`),
          },
          {
            text: routeGroupName,
          },
        ]}
        actions={[
          {
            label: "Delete",
            onClick: handleDeleteClick,
            display: true,
            isRiskAction: true,
            icon: "trash",
          },
        ]}
      />
    </div>
  );
};

export default RouteGroupDetails;
