import React, { useEffect, useState } from "react";
import type { CellValue, IdType } from "react-table";
import Table from "../Table/Table";
import { ButtonGroup } from "./ButtonGroup";
import { TableSearch } from "./TableSearch";
import SdColors from "../../styles/colors";
import usePagination from "../../hooks/UsePagination";

export type Column = {
  Header: string;
  show?: boolean;
  accessor: string;
};

type Props<DataT> = {
  columns?: Column[];
  data?: DataT[];
  onActionCallback?: (row: Record<IdType<DataT>, CellValue>) => void;
  onRowClickCallback?: (row: DataT) => void;
  pageSize?: number;
  enableSearch?: boolean;
};

function PaginatedTable<
  DataT extends object = Record<string, React.ReactNode>
>({
  columns = [],
  data = [],
  onActionCallback,
  onRowClickCallback,
  pageSize,
  enableSearch = true,
}: Props<DataT>) {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (!enableSearch) setFilteredData(data);
  }, [data]);

  const {
    currentPage,
    onPageChange,
    totalPages,
    data: paginatedData,
  } = usePagination<DataT>({ data: filteredData, pageSize });

  const handleFilteredData = (filtered: DataT[]) => {
    setFilteredData(filtered);
  };

  return (
    <div className="pt-2 pb-5">
      {enableSearch && (
        <TableSearch
          columns={columns}
          data={data}
          handleFilteredData={handleFilteredData}
        />
      )}
      <div
        style={{
          backgroundColor: SdColors.PRIMARY_MAIN,
          color: SdColors.NEUTRAL50,
          maxWidth: "100%",
          overflowX: "auto",
        }}
      >
        <Table<DataT>
          columns={columns}
          data={paginatedData}
          onActionCallback={onActionCallback}
          onRowClickCallback={onRowClickCallback}
        />
      </div>
      {totalPages > 1 && (
        <ButtonGroup
          pageCount={totalPages}
          onPageChange={onPageChange}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}

export default PaginatedTable;
