import React, { useMemo, createContext, useContext, useState } from "react";
import type { ContainerEnvVar } from "../../../../../../@types/k8s/container";
import type { EnvVar } from "../../../../../DeploymentDetails/AggregateEnvVars";
import type { MappedEnvVars } from "./utils";
import {
  getValue,
  formatBaselineEnvVars,
  mergeEnvVars,
  determineSourceState,
  envVarsToMap,
} from "./utils";
import type { FilterViewRowBase } from "../../../../../../components/FilteredView/FilteredView";
import type { SandboxV2 } from "../../../../../../@types/sd/sandboxv2";
import { mergeSandboxSpecForEnvVarsUpdate } from "../mergeSandboxSpecForEnvVarsUpdate";
import { getSandboxSpecAsYAML } from "../../../../../../components/theming/ViewSpecDialog/hook";
import type { DetermineSourceStateReturn } from "../utils";

export type ComputedEnvVar = EnvVar & {
  source: DetermineSourceStateReturn;
} & FilterViewRowBase;

type ApplyChangesToSandboxHandler = (updatedSpec: string) => void;

type EditingState =
  | {
      envVarName: string;
      value: string;
    }
  | undefined;

type EnvVarEditorContextType = {
  /** getMergedEnvVars returns a list of EnvVar which is the result of merging
   *  baseline + fork */
  computedEnvVars: ComputedEnvVar[];

  addEnvVar: (envVar: EnvVar) => void;
  deleteEnvVar: (envVar: EnvVar) => void;

  arePendingChanges: boolean;

  applyChangesToSandbox: () => void;

  vars: MappedEnvVars;

  editing: EditingState;
  setEditing: (editingState: EditingState) => void;
};

const EnvVarEditorContext = createContext<EnvVarEditorContextType>(
  {} as EnvVarEditorContextType
);

type EnvVarEditorProviderProps = {
  children: React.ReactNode;
  baselineEnvVars: ContainerEnvVar[];
  forkEnvVars: EnvVar[];
  baselineName: string;
  forkNamespace: string;
  sandbox: SandboxV2;
  onApplyChanges: ApplyChangesToSandboxHandler;
};

export const EnvVarEditorProvider = ({
  baselineEnvVars,
  forkEnvVars,
  baselineName,
  forkNamespace,
  onApplyChanges,
  sandbox,
  children,
}: EnvVarEditorProviderProps) => {
  const [envVarsUpdates, setEnvVarUpdates] = useState<MappedEnvVars>(new Map());
  const [editing, setEditing] = useState<EditingState>();

  const updateEnvVar = (envVar: EnvVar) => {
    setEnvVarUpdates(
      (prev) => new Map<string, EnvVar>(prev.set(envVar.name, envVar))
    );
  };

  const formattedBaseline = useMemo(
    () => formatBaselineEnvVars(baselineEnvVars),
    [baselineEnvVars]
  );

  const formattedFork = useMemo(() => envVarsToMap(forkEnvVars), [forkEnvVars]);

  // This is the result of joining baseline + forks. Where the forks override baseline vars
  const mergedEnvVarsWithoutUpdates = useMemo(
    () => mergeEnvVars(formattedBaseline, formattedFork),
    [formattedBaseline, formattedFork]
  );

  const computeEnvVars = (
    envVars: MappedEnvVars,
    updates: MappedEnvVars
  ): Map<string, ComputedEnvVar> => {
    const computedEnvVars: Map<string, ComputedEnvVar> = new Map();

    updates.forEach((env) => {
      const envName = env.name;

      const mergedVar = envVars.get(envName);
      if (!mergedVar) {
        // User added but didn't confirm and want to delete
        if (env.type === "delete") {
          computedEnvVars.delete(envName);
          return;
        }

        const base: ComputedEnvVar = {
          ...env,
          source: {
            source: "added",
            displayValue: getValue(env),
            toBeApplied: true,
          },
          id: envName,
        };

        computedEnvVars.set(envName, base);
        return;
      }

      // Means the intent of modified result in no change
      if (getValue(env) === getValue(mergedVar)) {
        return;
      }

      if (env.type === "delete") {
        const base: ComputedEnvVar = {
          ...env,
          source: {
            source: "deleted",
            displayValue: getValue(env),
            toBeApplied: true,
            restoreTo: getValue(mergedVar),
          },
          id: envName,
        };

        computedEnvVars.set(envName, base);
        return;
      }

      const base: ComputedEnvVar = {
        ...env,
        source: {
          source: "modified",
          displayValue: getValue(env),
          toBeApplied: true,
          restoreTo: getValue(mergedVar),
        },
        id: envName,
      };
      computedEnvVars.set(envName, base);
    });

    // Compute the unchanged vars
    envVars.forEach((env) => {
      if (computedEnvVars.has(env.name)) return;

      computedEnvVars.set(env.name, {
        ...env,
        source: determineSourceState(env, formattedBaseline, formattedFork),
        id: env.name,
      });
    });

    return computedEnvVars;
  };

  const computedEnvVars = useMemo(
    () => computeEnvVars(mergedEnvVarsWithoutUpdates, envVarsUpdates),
    [mergedEnvVarsWithoutUpdates, envVarsUpdates]
  );

  const computedEnvVarsAsArray = Array.from(computedEnvVars.values());

  const addEnvVar = (envVar: EnvVar) => {
    updateEnvVar(envVar);
  };

  const deleteEnvVar = (envVar: EnvVar) => {
    updateEnvVar({
      name: envVar.name,
      container: envVar.container,
      type: "delete",
      operation: "delete",
    });
  };

  // If is from baseline and is deleted -> type delete (add)
  // If is from baseline and was removed and now is add it again -> don't add
  // If is from baseline and is modified -> type value (add)

  // If is from fork and is added -> type value (add)
  // If is from fork and is modified -> type value (add)
  // If is from fork and is deleted -> don't add
  const getCleanedEnvsToApply = (
    baseline: MappedEnvVars,
    fork: MappedEnvVars
  ): EnvVar[] => {
    const filteredVars: MappedEnvVars = new Map();
    const skipped = new Set<string>();

    envVarsUpdates.forEach((env) => {
      const baselineVar = baseline.get(env.name);
      const forkVar = fork.get(env.name);
      const mergedVar = mergedEnvVarsWithoutUpdates.get(env.name);
      const computedVar = computedEnvVars.get(env.name) as ComputedEnvVar;

      // If is first time, added
      if (!mergedVar) {
        filteredVars.set(env.name, env);
        return;
      }

      if (baselineVar) {
        if (env.type === "delete") {
          filteredVars.set(env.name, env);
          return;
        }

        if (
          mergedVar &&
          computedVar.source.source === "modified" &&
          getValue(baselineVar) === getValue(env)
        ) {
          skipped.add(env.name);
          return;
        }

        filteredVars.set(env.name, env);
        return;
      }

      if (forkVar && env.type === "delete") {
        skipped.add(env.name);
        return;
      }

      filteredVars.set(env.name, env);
    });

    // Add unchanged forks vars
    fork.forEach((env) => {
      if (filteredVars.has(env.name)) return;
      if (skipped.has(env.name)) return;

      filteredVars.set(env.name, env);
    });

    return Array.from(filteredVars.values());
  };

  const applyChangesToSandbox = () => {
    const updatedSpec = mergeSandboxSpecForEnvVarsUpdate({
      sandbox,
      envs: getCleanedEnvsToApply(formattedBaseline, formattedFork),
      fork: {
        name: baselineName ?? "",
        namespace: forkNamespace,
      },
    });

    onApplyChanges(getSandboxSpecAsYAML(updatedSpec));

    setEnvVarUpdates(new Map());
  };

  const arePendingChanges = computedEnvVarsAsArray.some(
    (env) => env.source.toBeApplied
  );

  const contextValue: EnvVarEditorContextType = useMemo(
    () => ({
      addEnvVar,
      applyChangesToSandbox,
      deleteEnvVar,
      setEditing,
      computedEnvVars: computedEnvVarsAsArray,
      editing,
      arePendingChanges: arePendingChanges,
      vars: envVarsUpdates,
    }),
    [forkEnvVars, envVarsUpdates, editing]
  );

  return (
    <EnvVarEditorContext.Provider value={contextValue}>
      {children}
    </EnvVarEditorContext.Provider>
  );
};

export const useEnvVarEditor = (): EnvVarEditorContextType => {
  const context = useContext(EnvVarEditorContext);
  if (!context) {
    throw new Error(
      "useEnvVarEditor must be used within an EnvVarEditorProvider"
    );
  }
  return context;
};
