import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Tag } from "@blueprintjs/core";

import styles from "./ResourceCard.module.css";
import type { Resource } from "../../../@types/sd/resource";
import SdTheme from "../../../styles/theme";
import LabeledCard from "../../../components/theming/LabeledCard/LabeledCard";

interface ResourceProps {
  sandboxId: string;
  // eslint-disable-next-line react/no-unused-prop-types
  sandboxName: string;
  resource: Resource;
}

const ResourceCard: React.FunctionComponent<ResourceProps> = ({
  sandboxId,
  resource,
  sandboxName,
}) => {
  const navigate = useNavigate();
  const { name, plugin } = resource;
  return (
    <LabeledCard
      label="resource"
      titleBarContent={
        resource.status ? (
          <Tag
            round
            style={{
              backgroundColor: resource.status.ready
                ? SdTheme.Status.good
                : SdTheme.Status.warning,
            }}
          >
            {resource.status.phase}
          </Tag>
        ) : null
      }
      buttonInfos={[
        {
          id: "view-resource-detail",
          icon: "list-detail-view",
          label: "View Details",
          onClick: () => navigate(`/sandbox/id/${sandboxId}/resource/${name}`),
        },
        {
          id: "view-resource-logs",
          icon: "console",
          label: "View Logs",
          onClick: () =>
            navigate(
              `/sandbox/name/${sandboxName}/logs?type=Resource&name=${name}`
            ),
        },
      ]}
    >
      <div className={styles.content}>
        <div>
          <div>
            <b>Name</b>
          </div>
          <div>{name}</div>
          <div className="mt-6">
            <b>Provisioned By</b>
          </div>
          <div className={styles.overflowEllipsis}>{plugin}</div>
        </div>
      </div>
    </LabeledCard>
  );
};

export default ResourceCard;
