import React, { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  InputGroup,
  Intent,
  Tag,
} from "@blueprintjs/core";
import classNames from "classnames";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import type { FilteredViewColumn } from "../../../../../../components/FilteredView";
import styles from "./EnvVarEditorColumns.module.css";
import colors from "./ColorsState.module.css";
import type { ComputedEnvVar } from "./Context";
import { useEnvVarEditor } from "./Context";
import type { ComputedEnvVarSource } from "./utils";
import { getSourceText } from "./utils";
import { getValue } from "./utils";

export const getPairColorBySource = (
  state: ComputedEnvVarSource,
  gradient?: "left" | "right"
): Record<string, boolean> | undefined => {
  if (!state.toBeApplied) return undefined;

  const isValid = ["deleted", "added", "modified"].includes(state.source);
  if (!isValid) return undefined;

  return {
    [colors.status]: true,
    [colors.status__modified]: state.source === "modified",
    [colors.status__added]: state.source === "added",
    [colors.status__deleted]: state.source === "deleted",
    [colors.left]: gradient === "right",
    [colors.right]: gradient === "left",
  };
};

export type ColumnEditorProps = {
  envVar: ComputedEnvVar;
};

type KeyViewerProps = ColumnEditorProps;

const KeyViewer = ({ envVar }: KeyViewerProps) => (
  <div
    className={classNames(
      styles.columnKey,
      getPairColorBySource(envVar.source, "right")
    )}
  >
    <span>{envVar.name}</span>
  </div>
);

type ValueViewerProps = ColumnEditorProps;

const ValueViewer = ({ envVar }: ValueViewerProps) => {
  const { setEditing } = useEnvVarEditor();

  if (["dynamic", "forkDynamic"].includes(envVar.source.source)) {
    return (
      <div className={styles.columnValue}>
        <Tag intent={Intent.NONE}>DYNAMIC</Tag>
      </div>
    );
  }

  return (
    <InputGroup
      value={envVar.source.displayValue}
      fill
      disabled
      rightElement={
        envVar.source.source !== "deleted" ? (
          <Button
            minimal
            icon="edit"
            onClick={() => {
              setEditing({
                value: getValue(envVar) ?? "",
                envVarName: envVar.name,
              });
            }}
          />
        ) : undefined
      }
    />
  );
};

type SourceViewerProps = ColumnEditorProps;

const SourceViewer = ({ envVar }: SourceViewerProps) => {
  let sourceText = getSourceText(envVar.source.source);
  if (envVar.source.source === "forkDynamic") {
    sourceText = `Value from "${
      envVar.source.displayValue ?? "Unknown Resource"
    }"`;
  }

  return (
    <div
      className={classNames(
        styles.columnSource,
        getPairColorBySource(envVar.source, "left")
      )}
    >
      <span>
        {envVar.source.toBeApplied ? "(Unsaved) " : ""}
        {sourceText}
      </span>
    </div>
  );
};

type ActionsViewerProps = ColumnEditorProps;

const ActionsViewer = ({ envVar }: ActionsViewerProps) => {
  const { deleteEnvVar, addEnvVar } = useEnvVarEditor();

  const noDynamic = envVar.source.source !== "dynamic";
  const showDelete = envVar.source.source !== "deleted" && noDynamic;
  const showRestore = envVar.source.restoreTo !== undefined && noDynamic;

  return (
    <div
      className={classNames(
        styles.columnActions,
        getPairColorBySource(envVar.source)
      )}
    >
      <ButtonGroup>
        <Popover2
          className={classNames({
            [styles.actionHide]: !showRestore,
            [styles.actionShow]: showRestore,
          })}
          content={`Restore env var to "${envVar.source.restoreTo}"`}
          isOpen={false}
        >
          <Tooltip2 content={`Restore env var to "${envVar.source.restoreTo}"`}>
            <Button
              minimal
              icon="undo"
              onClick={() =>
                addEnvVar({
                  type: "value",
                  name: envVar.name,
                  container: envVar.container,
                  value: envVar.source.restoreTo,
                })
              }
            />
          </Tooltip2>
        </Popover2>
        <Popover2
          className={classNames({
            [styles.actionHide]: !showDelete,
            [styles.actionShow]: showDelete,
          })}
          content="Delete env var"
          isOpen={false}
        >
          <Tooltip2 content="Delete env var">
            <Button minimal icon="trash" onClick={() => deleteEnvVar(envVar)} />
          </Tooltip2>
        </Popover2>
      </ButtonGroup>
    </div>
  );
};

export const ENV_VAR_EDITOR_COLUMNS: FilteredViewColumn<ComputedEnvVar>[] = [
  { id: "key", render: (envVar) => <KeyViewer envVar={envVar} />, weight: 3 },
  {
    id: "value",
    render: (envVar) => <ValueViewer envVar={envVar} />,
    weight: 2,
  },
  {
    id: "source",
    render: (envVar) => <SourceViewer envVar={envVar} />,
    weight: 2,
  },
  {
    id: "actions",
    render: (envVar) => <ActionsViewer envVar={envVar} />,
    weight: 1,
  },
];
