import type { ReactNode } from "react";
import React from "react";
import ActionLink from "../../../../components/theming/ActionLink";
import styles from "../../../../components/Layout/Layout.module.css";
import {
  OPEN_IN_NEW_TAB,
  SmartTestsAnnouncementClick,
} from "../../../../Constants";

export interface AnnouncementData {
  content: ReactNode;
  featureFlagName?: string;
}

const defaultItems: AnnouncementData[] = [
  {
    content: (
      <div>
        💵{" "}
        <ActionLink
          className={styles.link}
          eventName={SmartTestsAnnouncementClick}
          onClick={() =>
            window.open("https://www.signadot.com/pricing", OPEN_IN_NEW_TAB)
          }
        >
          Pricing & Limits:
        </ActionLink>{" "}
        The pricing for the <b>Free</b> and <b>Business</b> plans is being
        updated to exclude updates to sandboxes. These changes will take effect
        in January 2025.
      </div>
    ),
  },
  {
    content: (
      <div>
        🚀 Trigger{" "}
        <ActionLink
          className={styles.link}
          eventName={SmartTestsAnnouncementClick}
          onClick={() =>
            window.open(
              "https://signadot.com/docs/guides/use-cases/run-smart-tests",
              OPEN_IN_NEW_TAB
            )
          }
        >
          AI-Powered Smart Tests
        </ActionLink>{" "}
        on your sandboxes automatically.
      </div>
    ),
  },
];

export default defaultItems;
