import React, { useMemo } from "react";
import type {
  LocalWorkloadStatus,
  LocalWorkloadWithStatus,
  SandboxV2,
} from "../../../../@types/sd/sandboxv2";
import Collapse from "../../../Testing/TestExecutionDetail/common/Collapse";
import { useSandboxDeploymentsApi } from "../../../../api/SandboxesApi";
import WorkloadTitle, {
  getDeploymentTitle,
} from "./WorkloadTitle/WorkloadTitle";
import DeploymentDetails from "./DeploymentDetails";
import styles from "./Workloads.module.css";
import LocalDetails from "./LocalDetails";
import { usePatchesApi } from "../../../../api/PatchesApi";
import {
  useDeploymentWorkloadStatusTag,
  useLocalWorkloadStatusTag,
} from "./WorkloadStatus";
import type { Workload } from "../../../../@types/sd/workload";
import Loading from "../../../../components/Loading/Loading";

type WorkloadsProps = {
  sandbox: SandboxV2;
};

type DeploymentWorkloadWrapperProps = {
  sandbox: SandboxV2;
  w: Workload;
};

const getWorkloadName = (workload: Workload) =>
  workload.baseline?.metadata.name ?? "";

const DeploymentWorkloadWrapper = ({
  sandbox,
  w,
}: DeploymentWorkloadWrapperProps) => {
  const statusTag = useDeploymentWorkloadStatusTag({
    sandboxId: sandbox.routingKey,
    workload: w,
  });

  const patches = usePatchesApi(sandbox.routingKey!, {
    name: getWorkloadName(w),
  });

  return (
    <Collapse
      title={<WorkloadTitle workload={w} isLocal={false} />}
      show
      tags={[{ className: styles.deployment_tag, label: "FORK" }, statusTag]}
      containerStyle={styles.container_workloads}
      bodyStyle={styles.body_workloads}
    >
      {patches.isLoading ? (
        <Loading />
      ) : (
        <DeploymentDetails workload={w} sandbox={sandbox} />
      )}
    </Collapse>
  );
};

type LocalWorkloadWrapperProps = {
  w: LocalWorkloadWithStatus;
};

const LocalWorkloadWrapper = ({ w }: LocalWorkloadWrapperProps) => {
  const statusTag = useLocalWorkloadStatusTag({
    workload: w,
  });

  return (
    <Collapse
      title={<WorkloadTitle workload={w} isLocal />}
      show
      tags={[{ className: styles.deployment_tag, label: "LOCAL" }, statusTag]}
    >
      <LocalDetails workload={w} />
    </Collapse>
  );
};

const Workloads = ({ sandbox }: WorkloadsProps) => {
  const deploymentsSearch = useMemo(
    () => ({ workspaceID: sandbox?.routingKey ?? "" }),
    [sandbox?.routingKey]
  );
  const runtimeInfo = useSandboxDeploymentsApi(deploymentsSearch);
  const deployments = useMemo(() => {
    if (runtimeInfo.isSuccess) {
      return runtimeInfo.data.deployments;
    }
    return [];
  }, [runtimeInfo.isSuccess, runtimeInfo.data]);

  // Local workloads
  const localWorkloadsWithStatus = useMemo(() => {
    if (!runtimeInfo.isSuccess || !sandbox) {
      return [];
    }
    // Local Workload status map
    const statusMap = sandbox?.status?.local?.reduce((map, lwStatus) => {
      map[lwStatus.name] = lwStatus;
      return map;
    }, {}) as Map<string, LocalWorkloadStatus>;

    return sandbox.spec.local?.map((localWorkload) => ({
      localWorkload: localWorkload,
      status: statusMap?.[localWorkload?.name],
    })) as LocalWorkloadWithStatus[];
  }, [runtimeInfo.isSuccess, sandbox]);

  return (
    <div>
      {deployments.map((w) => (
        <DeploymentWorkloadWrapper
          sandbox={sandbox}
          w={w}
          key={getDeploymentTitle(w)}
        />
      ))}
      {(localWorkloadsWithStatus ?? []).map((w) => (
        <LocalWorkloadWrapper w={w} key={w.localWorkload.from.name} />
      ))}
    </div>
  );
};

export default Workloads;
