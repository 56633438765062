import React from "react";
import EnvVarEditor from "./EnvVarEditor";
import { EnvVarEditorProvider } from "./Context";

type EnvVarEditorWrappedProps = Omit<
  React.ComponentProps<typeof EnvVarEditorProvider>,
  "children"
>;

const EnvVarEditorWrapped = (props: EnvVarEditorWrappedProps) => (
  <EnvVarEditorProvider {...props}>
    <EnvVarEditor />
  </EnvVarEditorProvider>
);

export default EnvVarEditorWrapped;
