import React from "react";
import { Icon } from "@blueprintjs/core";
import type { SandboxStatusV2 } from "../../../@types/sd/sandboxv2";
import styles from "./SandboxStatus.module.css";
import type {
  StatusColor,
  StatusStandard,
} from "../../../components/MultiTabsLayout/MultiTabsLayout";

interface SandboxStatusProps {
  status?: SandboxStatusV2;
}

const getStatusStyle = (status?: {
  progressing?: boolean;
  ready?: boolean;
}) => {
  if (!status) return styles.unknown;
  if (status.progressing) return styles.notReady;
  if (status.ready) return styles.ready;
  return styles.notReady;
};

const SANDBOX_STATUS_MAP: {
  isMatch: (status: SandboxStatusV2) => boolean;
  text: string;
  color: StatusColor;
}[] = [
  {
    isMatch: (status: SandboxStatusV2) => !status,
    text: "Unknown",
    color: "note",
  },
  {
    isMatch: (status: SandboxStatusV2) => status.progressing,
    text: "Not Ready",
    color: "error",
  },
  {
    isMatch: (status: SandboxStatusV2) => status.ready,
    text: "Ready",
    color: "success",
  },
  {
    isMatch: () => true,
    text: "Not Ready",
    color: "error",
  },
];

export const getSandboxStatus = (
  status: SandboxStatusV2 | undefined
): StatusStandard | undefined => {
  if (!status) return undefined;

  const matchedStatus = SANDBOX_STATUS_MAP.find((s) => s.isMatch(status));

  return {
    getText: () => matchedStatus?.text ?? "Unknown",
    getColor: () => matchedStatus?.color ?? "note",
  };
};

const SandboxStatus: React.FC<SandboxStatusProps> = ({ status }) => (
  <Icon icon="dot" size={32} className={getStatusStyle(status)} />
);

export default SandboxStatus;
