import type { LocalWorkloadWithStatus } from "../../../../@types/sd/sandboxv2";

export const HOSTNAME_LABEL = "signadot.com/hostname";
export const USERNAME_LABEL = "signadot.com/user";

export const getLocalConnectedPath = (
  localWorkloadWithStatus: LocalWorkloadWithStatus,
) => {
  const { status } = localWorkloadWithStatus;
  const labels = status?.tunnel.info?.labels;
  const hostname = labels?.[HOSTNAME_LABEL];
  const username = labels?.[USERNAME_LABEL];

  if (!hostname) {
    return undefined;
  }

  if (username) {
    return `${username}@${hostname}`;
  }

  return hostname;
};
