import React from "react";
import type { Params } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import {
  JobDetailClickArtifactsTab,
  JobDetailClickLogsTab,
  JobDetailClickOverviewTab,
  JobDetailClickSpecificationTab,
} from "../../../../Constants";
import Overview from "./tabs/Overview";
import Specification from "./tabs/Specification";
import { useGetJob } from "../../../../api/JobsApi";
import Artifacts from "./tabs/Artifacts";
import Logs from "./tabs/Logs";
import MultiTabsLayout from "../../../../components/MultiTabsLayout/MultiTabsLayout";
import JobDetailStatus from "./JobDetailStatus";
import CancelCurrentJob from "./CancelCurrentJobAlert";
import useDisclosure from "../../../../hooks/UseDisclosure";
import {getJobStatus} from "./JobDetailStatus/JobDetailStatus";

const JobDetail: React.FC = () => {
  const navigate = useNavigate();
  const cancelJobAlertState = useDisclosure(false);
  const { jobName } = useParams<Params>();
  const { data: job, isSuccess, isError, error } = useGetJob(jobName!);
  if (isError) {
    // @ts-ignore
    return <div>Error: {error?.message}</div>;
  }
  if (!isSuccess || !job) {
    return null;
  }

  const tabs = [
    {
      label: "Overview",
      slug: "overview",
      eventName: JobDetailClickOverviewTab,
      content: <Overview job={job} />,
    },
    {
      label: "Specification",
      slug: "specification",
      eventName: JobDetailClickSpecificationTab,
      content: <Specification job={job} />,
    },
    {
      label: "Artifacts",
      slug: "artifacts",
      eventName: JobDetailClickArtifactsTab,
      content: <Artifacts job={job} />,
    },
    {
      label: "Logs",
      slug: "logs",
      eventName: JobDetailClickLogsTab,
      content: <Logs job={job} />,
    },
  ];

  return (
    <>
      <CancelCurrentJob
        job={job}
        onCancel={cancelJobAlertState.close}
        isOpen={cancelJobAlertState.isOpen}
        close={() => cancelJobAlertState.close()}
      />
      <MultiTabsLayout
        tabs={tabs}
        title={`${jobName}`}
        status={getJobStatus(job.status.attempts[0].phase)}
        actions={[
          {
            label: "Cancel Job",
            onClick: cancelJobAlertState.open,
            display:
              job.status.attempts[0].phase === "queued" ||
              job.status.attempts[0].phase === "running",
          },
        ]}
        breadcrumbs={[
          {
            // icon: <Icon icon="lab-test" />,
            text: "Jobs",
            onClick: () => navigate(`/testing/jobs`),
          },
          {
            text: `${jobName}`,
          },
        ]}
      />
    </>
  );
};

export default JobDetail;
