import type { ContainerEnvVar } from "../../../../../../@types/k8s/container";
import type {
  EnvVar,
  EnvVarFrom,
  EnvVarValue,
} from "../../../../../DeploymentDetails/AggregateEnvVars";

export type SourceState =
  | "inherited"
  | "modified"
  | "deleted"
  | "added"
  | "dynamic"
  | "forkDynamic";

export const getSourceText = (source: SourceState): string => {
  switch (source) {
    case "dynamic":
    case "inherited":
      return "Inherited from baseline";
    case "modified":
      return "Modified in sandbox";
    case "added":
      return "Added in sandbox";
    case "deleted":
      return "Removed in sandbox";
    default:
      return "Source not found";
  }
};

export type ComputedEnvVarSource = {
  source: SourceState;
  toBeApplied?: boolean;
  displayValue?: string;
  restoreTo?: string;
};

const containerEnvVarToEnvVar = (envVar: ContainerEnvVar): EnvVarValue => ({
  type: "value",
  name: envVar.name,
  container: envVar.container,
  value: envVar.value,
});

export type MappedEnvVars = Map<string, EnvVar>;

export const formatBaselineEnvVars = (
  baselineEnvVars: ContainerEnvVar[]
): MappedEnvVars => {
  const map = new Map<string, EnvVar>();

  baselineEnvVars.forEach((env) => {
    map.set(env.name, containerEnvVarToEnvVar(env));
  });

  return map;
};

export const envVarsToMap = (envVars: EnvVar[]): MappedEnvVars => {
  const map = new Map<string, EnvVar>();

  envVars.forEach((env) => map.set(env.name, env));

  return map;
};

/**
 * Merges baseline env vars with fork env vars:
 * - If the fork overrides or deletes a baseline var, it is used in place of the baseline var.
 * - Any fork-only vars (i.e., new additions) are appended.
 */
export const mergeEnvVars = (
  baselineEnvVars: MappedEnvVars,
  forkEnvVars: MappedEnvVars
): MappedEnvVars => {
  const merged = new Map(baselineEnvVars);

  forkEnvVars.forEach((forkVar, name) => {
    if (forkVar.type === "delete" && !merged.has(name)) return;

    merged.set(name, forkVar);
  });

  return merged;
};

export const getValue = (env: EnvVar | undefined) =>
  (env as EnvVarValue | undefined)?.value;

export const determineSourceState = (
  envVar: EnvVar,
  baselineVars: MappedEnvVars,
  forkVars: MappedEnvVars
): ComputedEnvVarSource => {
  const targetVarName = envVar.name;

  const forkVar = forkVars.get(targetVarName);
  const baselineVar = baselineVars.get(targetVarName);

  const forkVarValue = getValue(forkVar);
  const baselineVarValue = getValue(baselineVar);

  if (forkVar) {
    if (forkVar.type === "delete") {
      return {
        source: "deleted",
        displayValue: "-",
        restoreTo: getValue(baselineVar),
      };
    }
    if (baselineVar) {
      if (baselineVarValue === forkVarValue) {
        return {
          source: "inherited",
          displayValue: forkVarValue,
        };
      }

      return {
        source: "modified",
        displayValue: forkVarValue,
        restoreTo: getValue(baselineVar),
      };
    }

    if ((forkVar as EnvVarFrom)?.valueFrom) {
      return {
        source: "forkDynamic",
        displayValue: `${(forkVar as EnvVarFrom)?.valueFrom?.resource?.name}:${
          (forkVar as EnvVarFrom)?.valueFrom?.resource?.outputKey
        }`,
      };
    }

    return {
      source: "added",
      displayValue: forkVarValue,
    };
  }

  if (baselineVar?.type === "value" && baselineVar.value === undefined) {
    return { source: "dynamic", displayValue: "-" };
  }

  return {
    source: "inherited",
    displayValue: baselineVarValue,
  };
};
