import { Intent } from "@blueprintjs/core";
import useApi from "../../../../hooks/UseApi";
import type { Workload } from "../../../../@types/sd/workload";
import type { CollapseTag } from "../../../Testing/TestExecutionDetail/common/Collapse/Collapse";
import type { LocalWorkloadWithStatus } from "../../../../@types/sd/sandboxv2";
import { getLocalConnectedPath } from "./utils";

type ContainerStatus = {
  state: {
    waiting: {
      reason: string;
    };
  };
};

type PodStatus = {
  conditions: {
    type: string;
    status: string;
    reason: string;
  }[];
  containerStatuses: ContainerStatus[];
  phase: string;
};
type Pod = {
  metadata: {
    name: string;
  };
  status: PodStatus;
};

type PodsResponse = {
  pods: Pod[];
};

const usePodsApi = (sandboxId: string, namespace: string, name: string) => {
  const apiName = ["pods"];
  if (sandboxId) {
    apiName.push(sandboxId);
  }
  if (namespace) {
    apiName.push(namespace);
  }
  if (name) {
    apiName.push(name);
  }
  return useApi<PodsResponse>(
    apiName,
    `/api/v1/orgs/:orgName/pods?${new URLSearchParams({
      workspaceID: sandboxId,
      namespace: namespace,
      name: name,
      onlyForks: "true",
    }).toString()}`,
    { retry: 1, refetchInterval: 5000 }
  );
};

export type DeploymentWorkloadStatusProps = {
  sandboxId: string;
  workload: Workload;
};

export const useDeploymentWorkloadStatusTag = ({
  sandboxId,
  workload,
}: DeploymentWorkloadStatusProps): CollapseTag => {
  const { fork } = workload;

  const namespace = fork?.metadata.namespace ?? "";
  const name = fork?.metadata.name ?? "";

  const api = usePodsApi(sandboxId, namespace, name);
  const { data, error, isError, isLoading, isIdle } = api;
  if (isError) {
    return {
      label: `Error fetching pods: ${error}`,
      intent: Intent.DANGER,
    };
  }

  if (isLoading || isIdle) {
    return { label: "Loading", intent: Intent.WARNING };
  }

  if (!Array.isArray(data.pods)) {
    return { label: "Loading", intent: Intent.WARNING };
  }

  const total = data.pods.length;
  const ready = data.pods.filter((p) => p.status.phase === "Running").length;

  return { label: `${ready}/${total} Pods Running`, intent: Intent.SUCCESS };
};

export type LocalWorkloadStatusProps = {
  workload: LocalWorkloadWithStatus;
};

export const useLocalWorkloadStatusTag = ({
  workload,
}: LocalWorkloadStatusProps): CollapseTag => {
  const localConnected = getLocalConnectedPath(workload);

  if (!localConnected) {
    return {
      label: "No hostname found",
      intent: Intent.DANGER,
    };
  }

  return {
    label: `Connected to ${localConnected}`,
    intent: Intent.SUCCESS,
  };
};
