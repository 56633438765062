import React, { useEffect, useRef, useState } from "react";
import { Editor as MonacoEditor, useMonaco } from "@monaco-editor/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { languages, editor } from "monaco-editor";
import type { IDisposable } from "monaco-editor";
import styles from "./Editor.module.css";
import { SIGNADOT_DARK_THEME } from "./constants";
import { loadLanguageImplementations } from "./languages";
import CodeLens = languages.CodeLens;
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;

type Options = editor.IStandaloneEditorConstructionOptions;

interface EditorProps {
  value?: string;
  defaultLanguage?: string;
  defaultValue?: string;
  language?: string;
  onChange?: (val: string | undefined) => void;
  options?: Options;
  header?: React.ReactNode;
  hideLineNumbers?: boolean;
  staticCodeLens?: CodeLens[];
  path?: string;
}

const Editor: React.FC<EditorProps> = ({
  value,
  defaultValue,
  defaultLanguage = "yaml",
  language,
  onChange,
  options,
  header,
  hideLineNumbers = false,
  staticCodeLens,
  path,
}) => {
  const languagesImplementation = useRef(loadLanguageImplementations());

  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const monaco = useMonaco();

  const [calculatedTheme, setCalculatedTheme] = useState<{
    backgroundColor?: string;
    color?: string;
  }>({ backgroundColor: "white" });

  useEffect(() => {
    if (!monaco) return;

    let codeLensProvider: IDisposable | null = null;

    languagesImplementation.current.onMonacoAvailable?.(monaco);

    if (staticCodeLens?.length) {
      codeLensProvider = monaco.languages.registerCodeLensProvider(
        language || defaultLanguage,
        {
          provideCodeLenses(): languages.ProviderResult<languages.CodeLensList> {
            return {
              lenses: staticCodeLens,
              dispose() {},
            };
          },
        }
      );
    }

    return () => {
      if (codeLensProvider) {
        codeLensProvider.dispose();
      }
    };
  }, [monaco]);
  const handleEditorDidMount = (e: IStandaloneCodeEditor) => {
    editorRef.current = e;

    const domNode = e.getDomNode();
    if (domNode) {
      const { backgroundColor, color } = window.getComputedStyle(domNode);
      setCalculatedTheme({ backgroundColor, color });
    }

    e.updateOptions({ wordWrap: "on", minimap: { enabled: false } });

    if (hideLineNumbers) {
      e.updateOptions({ lineNumbers: "off" });
    }
  };

  return (
    <div className={styles.wrapper} style={{ ...calculatedTheme }}>
      {header && <div className={styles.header}>{header}</div>}
      <MonacoEditor
        className={styles.editor}
        language={language}
        defaultLanguage={language ?? defaultLanguage}
        value={value}
        defaultValue={defaultValue}
        path={path}
        onChange={onChange}
        options={options}
        theme={SIGNADOT_DARK_THEME}
        beforeMount={(monacoInstance) => {
          languagesImplementation.current.beforeMount?.(monacoInstance);
        }}
        onMount={handleEditorDidMount}
      />
    </div>
  );
};

export default Editor;
