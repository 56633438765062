import React from "react";
import { Card, Elevation, H5 } from "@blueprintjs/core";
import Spacer from "../../../../../components/Util/Util";
import type { LocalWorkloadWithStatus } from "../../../../../@types/sd/sandboxv2";
import styles from "./LocalDetails.module.css";
import { getLocalConnectedPath } from "../utils";

type LocalDetailsProps = {
  workload: LocalWorkloadWithStatus;
};

const LocalDetails = ({
  workload: localWorkloadWithStatus,
}: LocalDetailsProps) => {
  const localConnected = getLocalConnectedPath(localWorkloadWithStatus);
  const { localWorkload } = localWorkloadWithStatus;

  return (
    <div>
      <Card className="m-2" elevation={Elevation.TWO}>
        <H5>
          <a href="#">Runtime Information</a>
        </H5>
        <Spacer />

        <div className={styles.details}>
          <p>
            <span className={styles.header}>Baseline</span>: Deployment{" "}
            <span>{localWorkload.from.name}</span> in namespace{" "}
            <span>{localWorkload.from.namespace}</span>
          </p>
          <p className={styles.line_info}>
            {localConnected ? (
              <>
                <span className={styles.header}>Sandboxed Workload</span>:
                Connected to <span>{localConnected}</span>
              </>
            ) : (
              <span>No local connected</span>
            )}
          </p>
        </div>
      </Card>
    </div>
  );
};

export default LocalDetails;
