import React from "react";
import { Intent } from "@blueprintjs/core";
import { STATUS } from "../../util/StatusUtil/StatusUtils";
import StatusCard from "./StatusCard";
import type { StatusStandard } from "../MultiTabsLayout/MultiTabsLayout";

type Props = {
  statusText?: string;
  statusDetail?: string;
  intent?: Intent;
  title?: string;
};

export const getResourceStatus = (
  isInProgress: boolean,
  statusText: string
): StatusStandard | undefined => {
  const re: StatusStandard = {
    getColor: "warning",
    getText: "",
  };

  if (isInProgress) {
    return {
      getColor: "warning",
      getText: "Not ready",
    };
  }

  if (statusText === STATUS.Healthy) {
    return {
      getText: "Ready",
      getColor: "success",
    };
  }

  re.getText = "Not Ready";
  re.getColor = "error";

  if (statusText === STATUS.Unknown) {
    re.getText = "Unknown";
    re.getColor = "warning";
  }

  return re;
};
const ResourceStatus: React.FunctionComponent<Props> = ({
  statusText,
  statusDetail,
  intent,
  title,
}) => {
  if (!title && !intent) {
    if (statusText === STATUS.Healthy) {
      return null;
    }

    title = "Not Ready";
    intent = Intent.DANGER;

    if (statusText === STATUS.Unknown) {
      title = "Unknown";
      intent = Intent.WARNING;
    }
  }
  return (
    <StatusCard title={title} description={statusDetail} intent={intent} />
  );
};

export default ResourceStatus;
