import type { SandboxV2 } from "../../../../../@types/sd/sandboxv2";
import type {
  EnvVar,
  EnvVarValue,
} from "../../../../DeploymentDetails/AggregateEnvVars";
import type {
  ForkOf,
  ForkCustomizationEnv,
} from "../../../../../@types/sd/sandbox";

export type MergeSandboxSpecOpts = {
  sandbox: SandboxV2;
  envs: EnvVar[];
  fork: Omit<ForkOf, "kind">;
};

export type MergeSandboxSpecImageOpts = {
  sandbox: SandboxV2;
  image: string;
  fork: Omit<ForkOf, "kind">;
};

const getMatchForkIndex = (
  sandbox: SandboxV2,
  forkDeployment: Omit<ForkOf, "kind">
) =>
  sandbox.spec.forks?.findIndex((fork) => {
    const f = fork.forkOf;

    return (
      f.name === forkDeployment.name && f.namespace === forkDeployment.namespace
    );
  });

const envVarToForkCustomizationEnv = (envs: EnvVar[]): ForkCustomizationEnv[] =>
  envs.map((e) => ({
    name: e.name,
    container: e.container,
    value: (e as EnvVarValue)?.value,
    operation: e.type === "delete" ? "delete" : "",
  }));

export const forkCustomizationEnvToEnvVar = (
  envs: ForkCustomizationEnv[]
): EnvVar[] => {
  const format = (env: ForkCustomizationEnv): EnvVar => {
    if (env.operation === "delete") {
      return {
        ...env,
        type: "delete",
        operation: "delete",
      };
    }

    return {
      type: "value",
      ...env,
    };
  };

  return envs.map(format);
};

// If is from baseline and is deleted -> type delete (add)
// If is from baseline and was removed and not is add it again -> don't add
// If is from baseline and is modified -> type value (add)

// If is from fork and is added -> type value (add)
// If is from fork and is modified -> type value (add)
// If is from fork and is deleted -> don't add
export const mergeSandboxSpecForEnvVarsUpdate = (ops: MergeSandboxSpecOpts) => {
  const { sandbox, envs, fork: forkDeployment } = ops;

  const indexFork = getMatchForkIndex(sandbox, forkDeployment);
  const forks = sandbox.spec.forks;

  if (indexFork === undefined || indexFork === -1 || !forks) return sandbox;

  const fork = forks[indexFork];

  const formattedVars = envVarToForkCustomizationEnv(envs);
  forks[indexFork] = {
    ...fork,
    customizations: {
      ...fork.customizations,
      env: [...formattedVars],
    },
  };
  sandbox.spec.forks = forks;

  return sandbox;
};

export const mergeSandboxSpecForImageUpdate = (
  ops: MergeSandboxSpecImageOpts
) => {
  const { sandbox, fork: forkDeployment, image } = ops;

  const indexFork = getMatchForkIndex(sandbox, forkDeployment);
  const forks = sandbox.spec.forks;

  if (indexFork === undefined || indexFork === -1 || !forks) return sandbox;

  const fork = forks[indexFork];

  forks[indexFork] = {
    ...fork,
    customizations: {
      ...fork.customizations,
      images: [
        {
          image: image,
        },
      ],
    },
  };
  sandbox.spec.forks = forks;

  return sandbox;
};
